@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.cdnfonts.com/css/bogart');
@import url('https://fonts.cdnfonts.com/css/now');

body {
  height: 100vh;
  margin: 0;
  font-family: 'Now';
  color: #034078;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
