#search-bar .ant-input-group-addon {
  background-color: #ffffff;
  border: none;
  color: #637381;
}

#search-bar .ant-select-selection-placeholder {
  color: #637381;
}

#search-bar .ant-select-arrow {
  color: #637381;
}
