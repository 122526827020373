.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input[type='file'] {
  opacity: 0;
  position: absolute;
  z-index: 99999;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #808080;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.ant-select-selector {
  height: 50px !important;
  padding: 0 10px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;

  font-size: 16px !important;
}

.ant-select-selection-item {
  display: flex;
}

.spinner {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-table-wrapper .ant-table {
  background-color: #fffef9;
}

#ParentManagementTable .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 30px 0 0 0;
  padding: 10px;
  background-color: #fffef9;
}

#ParentManagementTable
  .ant-pagination
  .ant-pagination-prev
  .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838995;
}

#ParentManagementTable
  .ant-pagination
  .ant-pagination-next
  .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #838995;
}

#ParentManagementTable .ant-pagination .ant-pagination-item a {
  color: #838995;
}

#ParentManagementTable .ant-pagination .ant-pagination-item-active {
  background-color: #212b36;
}

#ParentManagementTable .ant-pagination .ant-pagination-item-active a {
  color: white;
}

#ParentManagementTable .ant-table-wrapper .ant-table-pagination > * {
  border: 1px solid #edeff1;

  #languages {
    .ant-select-selector {
      height: fit-content !important;
      padding: 10px !important;
    }
  }
}

#languages2 {
  .ant-select-selector {
    height: 55px !important;
    padding: 10px 15px !important;
    color: rgb(107, 114, 128);
  }
}

.ant-picker-footer button {
  background-color: #5c60e5;
}

.ant-picker-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.ant-picker .ant-picker-input > input[disabled] {
  cursor: auto !important;
}

.ant-spin .ant-spin-dot-item {
  background-color: #838995;
}

#EditableSelect .ant-select-selector {
  height: 35px !important;
}

.ant-upload-wrapper {
  text-align: center;
}
